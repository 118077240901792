import React, { useContext } from 'react';
import GoogleAuthorize from 'react-google-authorize';
import { graphql, navigate } from 'gatsby';
import styled from '@emotion/styled';
import Image from 'gatsby-image';
import GlobalLayout from '../../components/layouts/global';
import { AuthContext, signInAction, postTypeAction } from '../../context/auth-context';

// Styled Components
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  gap: 1.5rem;
`;

const GoogleButton = styled.button`
  background-color: transparent;
  border: 0;
  cursor: pointer;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AdminPage = ({ data }) => {
  const { state, dispatch } = useContext(AuthContext);

  const getGoogleUser = async (response) => {
    const request = await fetch(
      `https://www.googleapis.com/oauth2/v3/userinfo?access_token=${response.access_token}`,
      { headers: { accept: 'application/json, text/plain, */*' } }
    );
    const payload = await request.json();
    if (payload.hd === 'alioit.com') {
      dispatch(signInAction(payload));
    } else {
      console.error('error on login');
    }
  };

  const handleCreatePost = (type) => {
    dispatch(postTypeAction(type)); // Set the post type in the context
  };

  if (state.isLoggedIn && state.postType === 'blog') {
    navigate('/admin/blog');
    return null;
  } else if (state.isLoggedIn && state.postType === 'career') {
    navigate('/admin/career');
    return null;
  }

  return (
    <GlobalLayout>
      <ButtonContainer>
        <GoogleAuthorize
          clientId="212251758525-mnr1navr9p26lipfrt56b22asd15r106.apps.googleusercontent.com"
          buttonText="Authorize"
          hostedDomain="alioit.com"
          onSuccess={getGoogleUser}
          onFailure={(e) => null}
          render={(renderProps) => (
            <>
              <GoogleButton onClick={() => { handleCreatePost('blog'); renderProps.onClick(); }}>
                <Image fixed={data.googleButton.fixed} alt="Google Sign-In" />
                <span style={{ marginLeft: '10px' }}>Create Blog Post</span>
              </GoogleButton>

              <GoogleButton onClick={() => { handleCreatePost('career'); renderProps.onClick(); }}>
                <Image fixed={data.googleButton.fixed} alt="Google Sign-In" />
                <span style={{ marginLeft: '10px' }}>Create Career Opportunity</span>
              </GoogleButton>
            </>
          )}
        />
      </ButtonContainer>
    </GlobalLayout>
  );
};

export const query = graphql`
  query {
    googleButton: imageSharp(
      fixed: { originalName: { eq: "google-signin.png" } }
    ) {
      fixed(jpegQuality: 100, quality: 100, width: 160) {
        ...GatsbyImageSharpFixed
      }
    }
  }
`;

export default AdminPage;
